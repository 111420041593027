import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function AdoptionSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="inner-breeder-area breeder-bg trending_slider_mobile">
      <div className="container">
        <div>
          <Slider className="breeder-active owl-carousel" {...settings}>
            <div className="breeder-item">
              <img src="img/trending/slide1.jpeg" alt="" />
            </div>
            <div className="breeder-item">
              <img src="img/trending/slide2.jpeg" alt="" />
            </div>
            <div className="breeder-item">
              <img src="img/trending/slide3.jpeg" alt="" />
            </div>
            <div className="breeder-item">
              <img src="img/trending/slide4.jpeg" alt="" />
            </div>
            {/* <div className="breeder-item">
              <img src="img/trending/slide5.jpeg" alt="" />
            </div> */}
          </Slider>
        </div>

       
      </div>
    </section>
  );
}

export default AdoptionSlider;
