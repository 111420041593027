import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCartItems,
  selectCartTotal,
  removeItem,
  clearCart,
} from "../../feature/cartSlice";
import RazorpayScript from "./Razorpay";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { v4 as uuidv4 } from "uuid";

function CheckoutComp() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const cartItems = useSelector(selectCartItems);
  const subTotal = useSelector(selectCartTotal);
  const history = useHistory();

  const user = localStorage.getItem("user");
  const user_id = JSON.parse(user);

  const idList = cartItems.map((item) => item?.id);
  const variant = cartItems.map((item) => item?.activeColor);
  const quantity = cartItems.map((item) => item?.quantity);
  const quantityString = quantity.join(", ");
  const variantString = variant.join(", ");

  const getSessionId = () => {
    const storedSessionId = localStorage.getItem("session_id");
    if (storedSessionId) {
      return storedSessionId;
    } else {
      const newSessionId = uuidv4();
      localStorage.setItem("session_id", newSessionId);
      return newSessionId;
    }
  };

  console.log("session", variant);

  const session_id = user_id ? "" : getSessionId();

  const [forms, setForms] = useState({
    user_id: user_id?.id ? user_id.id : "",
    session_id: session_id,
    first_name: "",
    last_name: "",
    address: "",
    postcode: "",
    email: "",
    phone: "",
    note: "",
    product_id: idList ? idList : "",
    variant: variantString ? variantString : "",
    subtotal: subTotal,
    quantity: quantity ? quantityString : "0",
  });
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    // Clear session_id from localStorage when the component unmounts
    return () => {
      if (user_id) {
        localStorage.removeItem("session_id");
      }
    };
  }, [user_id]);

  const baseUrl = process.env.REACT_APP_API_PUBLIC_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data submitted:", forms);

    try {
      const response = await axios.post(`${baseUrl}/place-order`, forms);

      console.log("res", response.data.data.prefill.name);
      const orderId = response.data.order_id;

      var options = {
        key: response.data.data.key,
        amount: response.data.data.amount,
        name: response.data.data.name,
        description: response.data.data.description,
        image: response.data.data.image,
        handler: function (response) {
          if (
            typeof response.razorpay_payment_id == "undefined" ||
            response.razorpay_payment_id < 1
          ) {
            console.log("not done");
          } else {
            axios
              .post(`${baseUrl}/payment/store`, {
                razorpay_payment_id: response.razorpay_payment_id,
                order_id: orderId,
              })
              .then((paymentResponse) => {
                history.push("/");
                dispatch(clearCart());
                addToast(paymentResponse.data.data, {
                  appearance: "success",
                  autoDismiss: true,
                });
                console.log("Payment stored:", paymentResponse.data.data);
              })
              .catch((error) => {
                dispatch(clearCart());
                console.error(
                  "Error storing payment on the backend:",
                  error.response ? error.response.data : error.message
                );
              });
          }
        },
        prefill: {
          name: response.data.data.prefill.name,
          email: response.data.data.prefill.email,
          contact: response.data.data.prefill.contact,
        },
        notes: {
          address: response.data.data.notes.address,
          city: response.data.data.notes.city,
        },
        theme: {
          color: response.data.data.theme.color,
        },
      };

      const razorpay = new window.Razorpay(options);

      razorpay.open();
    } catch (error) {
      clearCart();
      console.error(
        "Error placing order:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div
      className="container"
      style={{ marginTop: "100px", marginBottom: "100px" }}
    >
      <RazorpayScript></RazorpayScript>
      <div className="row">
        <div className="col-md-6">
          <div className="cuponWrap checkoutCard">
            {/* <button
              className="btn btn-primary collapseBtn"
              type="button  "
              data-toggle="collapse"
              data-target="#billingAddress"
              aria-expanded="false"
              aria-controls="billingAddress"
            >
              Billing Address
              <i className="fa fa-plus"></i>
            </button> */}
            <div className="" id="billingAddress">
              <div className="chCardBody">
                <form className="cuponForm" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          className="form-control"
                          type="text"
                          id="first_name"
                          name="first_name"
                          value={forms.first_name}
                          onChange={(e) => changeHandler(e)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          className="form-control"
                          type="text"
                          id="last_name"
                          name="last_name"
                          value={forms.last_name}
                          onChange={(e) => changeHandler(e)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          id="address"
                          name="address"
                          value={forms.address}
                          onChange={(e) => changeHandler(e)}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="postcode">Post Code</label>
                        <input
                          className="form-control"
                          type="text"
                          id="postcode"
                          name="postcode"
                          value={forms.postcode}
                          onChange={(e) => changeHandler(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <input
                          className="form-control"
                          type="email"
                          id="email"
                          name="email"
                          value={forms.email}
                          onChange={(e) => changeHandler(e)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="phone">Phone No</label>
                        <input
                          className="form-control"
                          type="text"
                          id="phone"
                          name="phone"
                          value={forms.phone}
                          onChange={(e) => changeHandler(e)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <div className="collapse" id="dif_ship"></div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="note">Order Notes</label>
                        <textarea
                          className="form-control note"
                          id="note"
                          name="note"
                          placeholder="Note about your order"
                          value={forms.note}
                          onChange={(e) => changeHandler(e)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        href="#"
                        type="submit"
                        className="btn btn-primary theme-btn-s3 cBtn cBtnLarge cBtnTheme mt-20"
                      >
                        Proceed to Checkout
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="cartStatus">
            <div className="row">
              <div className="col-12">
                <div className="cartTotals mobile_cart_margin">
                  <h4>Cart Total</h4>
                  <table className="table">
                    <tbody>
                      {cartItems.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <b>{item.name}</b> ₹{item.price} x {item.quantity}
                          </td>
                          <td className="text-right">₹{subTotal}</td>
                        </tr>
                      ))}
                      <tr className="totalProduct">
                        <td>Total product</td>
                        <td className="text-right">{cartItems.length}</td>
                      </tr>
                      <tr>
                        <td>Sub Price</td>
                        <td className="text-right">₹{subTotal}</td>
                      </tr>
                      <tr>
                        <td>Total Price</td>
                        <td className="text-right">₹{subTotal}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutComp;
