import React from "react";
import { Link } from "react-router-dom";

function Adoption() {
  return (
    <section className="adoption-area" style={{marginTop:"50px"}}>
      <div className="container">
        <div className="row align-items-center align-items-xl-end justify-content-center">
          <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
            <div className="adoption-img">
              <img src="img/images/banner_chilli.png" alt="" />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="adoption-content">
              <h2 className="title">
                Discover <br /> Exquisite <span>Flavors</span> with Our Masalas
              </h2>
              <p>
                Dive into a world of taste and aroma with our extensive
                selection of masalas, spices, and seasonings crafted to elevate
                your culinary experience.
              </p>
              <Link to="/shop" className="btn">
                Explore Masala Collection{" "}
                <img src="img/icon/toy_icon.png" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Adoption;
