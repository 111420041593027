import React from "react";
import Slider from "react-slick";

function AdoptionGallery() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="breeder-gallery-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <Slider className="breeder-gallery-active" {...settings}>
              <div className="breeder-gallery-item">
                <img src="img/images/reg1.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg2.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg3.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg4.webp" alt="" />
              </div>
            </Slider>
          </div>
          <div className="col-lg-6">
            <div className="adoption-content">
              <h5 className="sub-title">Discover Flavorful Bliss</h5>
              <h2 className="title">
                Explore a World of <span>Aromatic Delights</span> <br />{" "}
                Culinary Magic
              </h2>
              <p>
                Immerse yourself in a world of flavor and joy with our extensive
                range of spices, meticulously selected for your culinary
                adventures. Our collection is crafted to provide endless taste
                sensations and culinary inspiration, ensuring that your dishes
                are filled with aromatic bliss.
              </p>
              <div className="adoption-list">
                <ul>
                  <li>
                    <i className="flaticon-tick" /> Aromatic Spice Collection
                  </li>
                  <li>
                    <i className="flaticon-tick" /> Meticulously Selected Blends
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdoptionGallery;
