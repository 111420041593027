import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchProductData } from "../../utils/apis";

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src="img/icon/arrow.png" alt="prev" />
    </button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src="img/icon/arrow.png" alt="next" />
    </button>
  );
}

function AdoptionPuppies() {
  const dummyAdoptionData = [
    {
      id: 1,
      image: "img/toys/data1.webp",
      name: "Dragon Ball",
      category: "Miniacture",
      releaseYear: 2020,
      rating: 5,
      price: "₹399",
    },
    {
      id: 2,
      image: "img/toys/data2.webp",
      name: "Spider Man",
      category: "Miniacture",
      releaseYear: 2021,
      rating: 5,
      price: "₹399",
    },
    {
      id: 3,
      image: "img/toys/data3.webp",
      name: "Barbie",
      category: "Doll",
      releaseYear: 2021,
      rating: 5,
      price: "₹500",
    },
    {
      id: 4,
      image: "img/toys/data4.webp",
      name: "New Spidey",
      category: "Miniacture",
      releaseYear: 2021,
      rating: 5,
      price: "Free",
    },
    {
      id: 5,
      image: "img/toys/data5.webp",
      name: "Light Year",
      category: "Toy",
      releaseYear: 2021,
      rating: 5,
      price: "₹500",
    },
    {
      id: 6,
      image: "img/toys/data6.webp",
      name: "Avengers",
      category: "Miniacture",
      releaseYear: 2021,
      rating: 5,
      price: "Free",
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };

  const { isPending, isError, data, error } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProductData,
  });

  const renderLoader = () => (
    <div className="text-center">
      <div className="spinner-border text-danger" role="status">
        <span className="visually-hidden"></span>
      </div>
    </div>
  );

  const renderError = () => <span>Error: {error?.message}</span>;

  return (
    <section className="adoption-area-two pt-110 pb-110">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <h5 className="sub-title">Discover Our Spice Collection</h5>
              <h2 className="title">
                Exquisite Flavors Ready to Spice Up Your Kitchen
              </h2>
              <p>
                Immerse yourself in a world of taste and aroma with our diverse
                range of spices available for adoption. We showcase the finest
                selection, from fragrant blends to exotic flavors that will
                elevate your culinary creations. Find the perfect match for
                endless culinary delight!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container custom-container">
        {isPending && renderLoader()}
        {isError ? renderError() : null}

        {!isPending && !isError && data && (
          <Slider className="row adopt-active" {...settings}>
            {data.map((item, index) => (
              <div key={item.id || index} className="col-xl">
                <div className="adoption-item">
                  <div className="adopt-thumb">
                    <img
                      src={item.image}
                      alt=""
                      style={{ height: "290px", width: "290px" }}
                    />
                    <span className="status">{item.price}</span>
                  </div>
                  <div className="adopt-content">
                    <div className="adopt-date">
                      <h5> {item.name}</h5>
                    </div>
                    <p>{item.description}</p>
                    <Link to={`/shop-details/${item.id}`} className="read-more">
                      Buy Now
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </section>
  );
}

export default AdoptionPuppies;
