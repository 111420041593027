import React from "react";
import Slider from "react-slick";

function RegisterGallery() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="breeder-gallery-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <Slider className="breeder-gallery-active" {...settings}>
              <div className="breeder-gallery-item">
                <img src="img/images/reg1.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg2.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg3.webp" alt="" />
              </div>
              <div className="breeder-gallery-item">
                <img src="img/images/reg4.webp" alt="" />
              </div>
            </Slider>
          </div>
          <div className="col-lg-6">
            <div className="adoption-content">
              <h5 className="sub-title">Explore Spice Sensations</h5>
              <h2 className="title">
                Exquisite Spice Blends <span>for Flavorful Moments</span>
              </h2>
              <p>
                Discover the finest spices for your culinary delights! Whether
                it's aromatic blends or exotic flavors, we have a wide selection
                to enhance your cooking experience. Explore our top-quality
                spices at Spice Emporium and make every meal a flavorful and
                memorable experience.
              </p>
              <div className="adoption-list">
                <ul>
                  <li>
                    <i className="flaticon-tick" /> Aromatic Spice Blends
                  </li>
                  <li>
                    <i className="flaticon-tick" /> Exotic Spice Flavors
                  </li>
                  <li>
                    <i className="flaticon-tick" /> Essential Ingredients
                  </li>
                  <li>
                    <i className="flaticon-tick" /> High-Quality and Flavorful
                    Selections
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegisterGallery;
