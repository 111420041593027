import React from "react";
import Slider from "react-slick";

function Testimonial() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="testimonial-area testimonial-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <h5 className="sub-title">Testimonials</h5>
              <h2 className="title">Our Happy Customers</h2>
              <p>
                Explore the diverse array of aromatic masala essentials with our
                Masala Exploration Kit (view at MasalaEmporium), offering
                insights into various masala blends and information about the
                latest culinary trends. Dive into the delight of spicing up your
                dishes with our extensive collection, crafted to enhance the
                flavor profiles of every culinary creation.
              </p>
            </div>
          </div>
        </div>
        <Slider className="row testimonial-active" {...settings}>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar01.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ AKA Masala Shop has transformed my cooking experience
                  completely! Their spices are of the highest quality, offering
                  an explosion of flavors in every dish. My family can't get
                  enough of the delicious meals I create using their masalas. ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Alessia Cara</h5>
                  <span>ToyEnthusiast CEO</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar02.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ I've been a loyal customer of AKA Masala Shop for years now,
                  and I can't imagine cooking without their spices. The
                  freshness and aroma of their masalas add an unparalleled depth
                  of flavor to my recipes. Highly recommended ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Alessia Cara</h5>
                  <span>Happy Parent</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar01.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ AKA Masala Shop has made cooking exciting again for me.
                  Their wide range of masalas allows me to experiment with
                  different cuisines and flavors effortlessly. I've received
                  numerous compliments on my dishes. ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Alessia Cara</h5>
                  <span>Excited New Parent</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="testimonial-item">
              <div className="testi-avatar-thumb">
                <img src="img/images/testi_avatar02.png" alt="" />
              </div>
              <div className="testi-content">
                <p>
                  “ As a professional chef, I rely on AKA Masala Shop for all my
                  spice needs. Their masalas are consistently top-notch,
                  providing the perfect balance of flavors for my culinary
                  creations. AKA Masala Shop is definitely a game-changer in the
                  world of spices ”
                </p>
                <div className="testi-avatar-info">
                  <h5 className="title">Alessia Cara</h5>
                  <span>Excited New Parent</span>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Testimonial;
