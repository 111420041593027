import React from "react";
import { Link } from "react-router-dom";


function Newsletter() {
  return (
    <div className="newsletter-area pb-110">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="newsletter-wrap">
              <div className="newsletter-content">
                <h2 className="title">Any Queries</h2>
                <p>
                  <span>*</span> Stay Connect we are ready to help you.
                </p>
              </div>
              <div className="">
                <Link to={`/contacts`}>
                  <button type="submit" className="btn">
                    Contact Us
                  </button>
                </Link>
              </div>
              {/* <div className="newsletter-shape"><img src="img/images/newsletter_shape01.png" alt="" /></div>
                <div className="newsletter-shape shape-two"><img src="img/images/newsletter_shape02.png" alt="" /></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Newsletter;
