import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ToastProvider } from "react-toast-notifications";

import $ from "jquery";

import App from "./App";

const queryClient = new QueryClient();
const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ToastProvider placement="top-center" autoDismissTimeout={3000}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ToastProvider>
  </Provider>
);
