import React, { useEffect } from "react";
import "magnific-popup";
import $ from "jquery";
import { Link } from "react-router-dom";

function Faq({ afterElment }) {
  useEffect(() => {
    /* magnificPopup video view */
    $(".popup-video").magnificPopup({
      type: "iframe",
    });
  }, []);

  return (
    <section className={afterElment + " faq-bg"}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="faq-img-wrap">
              <img src="img/images/faq_tv.png" className="img-frame" alt="" />
              <img src="img/images/faq_img.webp" className="main-img" alt="" />
              {/* Update the link to your toy-related video */}
              <Link
                to="https://www.youtube.com/watch?v=fTRMKsy242c"
                className="popup-video"
              ></Link>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="faq-wrapper">
              <div className="section-title mb-35">
                <h5 className="sub-title">FAQ Question</h5>
                <h2 className="title">Discover More About Our Service</h2>
              </div>
              <div className="accordion" id="accordionExample">
                {/* Question 1 */}
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What sizes are available for your baby clothing items?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      We offer a wide range of sizes to accommodate newborns to
                      toddlers. Our sizing chart is available on each product
                      page to help you find the perfect fit for your little one.
                    </div>
                  </div>
                </div>
                {/* Question 2 */}
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Are your baby products safe and free from harmful
                        chemicals?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Absolutely! We prioritize the safety and well-being of
                      your child. All our baby products are made from
                      high-quality, non-toxic materials, and they comply with
                      safety standards. We understand the importance of
                      providing safe and comfortable products for your little
                      ones.
                    </div>
                  </div>
                </div>
                {/* Question 3 */}
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        How can I track my order once it's placed?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Once your order is shipped, you will receive a
                      confirmation email with a tracking number. You can use
                      this tracking number to monitor the status and location of
                      your package. Additionally, you can log in to your account
                      on our website to view order details and tracking
                      information.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq-shape">
        <img src="img/images/faq_shape.png" alt="" />
      </div>
    </section>
  );
}

export default Faq;
