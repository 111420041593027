import React from "react";
import { Link } from "react-router-dom";

function Find() {
  return (
    <div className="find-area">
      <div className="container custom-container">
        <div className="row">
          <div className="col-12">
            <form>
              <div className="find-wrap">
                <div className="location">
                  <i className="flaticon-location" />
                  <input type="text" defaultValue="Enter City, State, or Zip" />
                </div>
                <div className="find-category">
                  <ul>
                    <li>
                      <Link to="/masalalist">
                        <i className="flaticon-spices" /> Masalas
                      </Link>
                    </li>
                    <li>
                      <Link to="/masalalist">
                        <i className="flaticon-chili-pepper" /> Spices
                      </Link>
                    </li>
                    <li>
                      <Link to="/masalalist">
                        <i className="flaticon-spice-bag" /> Seasonings
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="other-find">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      More Masala Options
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link className="dropdown-item" to="/masalalist">
                        Spice Blends
                      </Link>
                      <Link className="dropdown-item" to="/masalalist">
                        Curry Powders
                      </Link>
                      <Link className="dropdown-item" to="/masalalist">
                        Herb Mixes
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Find;
