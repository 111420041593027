import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function RegisterSlider() {
  const history = useHistory();

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const [focusedInput, setFocusedInput] = useState(null);
  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const baseUrl = process.env.REACT_APP_API_PUBLIC_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("s", formData);
      const response = await axios.post(`${baseUrl}/register`, {
        email: formData.email,
        name: formData.username,
        password: formData.password,
      });

      console.log(response.data); // Log the response

      toast.success("Registration Complete successfully!");
      history.push("/login");
    } catch (error) {
      console.error(
        "Error registering user:",
        error.response.data.error
      );
      if (error.response.data.error && error.response.data.error.password) {
        toast.error("Invalid password. Please use a stronger password.");
      } else if (error.response.data.error && error.response.data.error.email) {
        toast.error(
          "Email is already registered. Please use a different email."
        );
      } else {
        toast.error("Failed to register. Please try again.");
      }
    }
  };

  return (
    <section className="inner-breeder-area breeder-bg">
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <h2>Sign up</h2>
          <div className="col-12">
            <form
              onSubmit={handleSubmit}
              style={{ maxWidth: "500px", margin: "auto" }}
            >
              <div
                className={`form-group${
                  focusedInput === "username" ? " focused" : ""
                }`}
              >
                <input
                  type="text"
                  className="form-control custom-input"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  onFocus={() => handleFocus("username")}
                  onBlur={handleBlur}
                  placeholder="Username"
                  required
                  style={{ height: "55px" }} // Adjust the height as needed
                />
              </div>
              <div
                className={`form-group${
                  focusedInput === "email" ? " focused" : ""
                }`}
              >
                <input
                  type="email"
                  className="form-control custom-input"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onFocus={() => handleFocus("email")}
                  onBlur={handleBlur}
                  placeholder="Email"
                  required
                  style={{ height: "55px" }} // Adjust the height as needed
                />
              </div>
              <div
                className={`form-group${
                  focusedInput === "password" ? " focused" : ""
                }`}
              >
                <input
                  type="password"
                  className="form-control custom-input"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  onFocus={() => handleFocus("password")}
                  onBlur={handleBlur}
                  placeholder="Password"
                  required
                  style={{ height: "55px" }} // Adjust the height as needed
                />
              </div>
              <button type="submit" className="btn" style={{ height: "40px" }}>
                Sign Up
              </button>
            </form>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <p>
                Already have an account?{" "}
                <Link style={{ color: "#008140" }} to="/login">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegisterSlider;
