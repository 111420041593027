import { HashRouter as Router, Switch } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import Home from "./pages/Home";
import ToyList from "./pages/ToyList";
import AdoptionsPages from "./pages/AdoptionsPages";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/BlogPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import ShopPages from "./pages/ShopPages";
import ShopDetailsPage from "./pages/ShopDetailsPage";
import BreederPage from "./pages/BreederPage";
import BreederDetailsPage from "./pages/BreederDetailsPage";
import HometwoPage from "./pages/HometwoPage";
import ScrollToTopRoute from "./ScrollToTopRoute";
import Register from "./pages/Register";
import Login from "./pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import MyOrders from "./pages/MyOrders";
import 'react-modern-drawer/dist/index.css'

function App() {
  return (
    <div className="app">
      <Router>
        <ToastContainer position="top-center" />
        <Header />
        <Switch>
          <ScrollToTopRoute exact={true} path="/">
            <Home />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/about-us">
            <ToyList />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/trending">
            <AdoptionsPages />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/contacts">
            <ContactPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/blogs">
            <BlogPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/blog-details">
            <BlogDetailsPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/shop">
            <ShopPages />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/shop-details/:id">
            <ShopDetailsPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/breeder">
            <BreederPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/toys-details">
            <BreederDetailsPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/register">
            <Register />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/login">
            <Login />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/cart">
            <Cart />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/checkout">
            <Checkout />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/my-orders">
            <MyOrders />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact={true} path="/home-two">
            <HometwoPage />
          </ScrollToTopRoute>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
