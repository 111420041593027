import React from "react";

function AdoptionFaq() {
  return (
    <section className="faq-area faq-two-bg">
      <div className="faq-two-img" />
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-xl-7 col-lg-6">
            <div className="faq-wrapper">
              <div className="section-title white-title mb-35">
                <h5 className="sub-title">Spice Lovers' Queries</h5>
                <h2 className="title">Explore Our Exotic Spice Collection</h2>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What makes your spice blends unique?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Our spice blends are meticulously crafted using
                      high-quality ingredients sourced from around the world.
                      Each blend is carefully balanced to ensure a perfect
                      harmony of flavors, making our spices stand out for their
                      exceptional taste and aroma.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Are your spices organic and sustainably sourced?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      Yes, we take great pride in offering organic and
                      sustainably sourced spices. We work directly with farmers
                      and suppliers who share our commitment to ethical and
                      environmentally friendly practices. This ensures that our
                      customers not only enjoy top-quality spices but also
                      contribute to a more sustainable food system.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        How should I store your spices to maintain their
                        freshness?
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      To preserve the freshness and flavor of our spices, we
                      recommend storing them in a cool, dry place away from
                      direct sunlight and moisture. Additionally, using airtight
                      containers can help extend the shelf life of your spices.
                      Avoid storing them near heat sources or in humid
                      environments, as exposure to these elements can degrade
                      their quality over time.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdoptionFaq;
