import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { login } from "../../utils/apis";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "../../feature/userSlice";
import axios from "axios";
import { clearCart } from "../../feature/cartSlice";

function LoginSlider() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [cancelToken, setCancelToken] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const source = axios.CancelToken.source();
    setCancelToken(source);

    try {
      const userData = await login(formData.email, formData.password);

      if (userData.status === true) {
        dispatch(clearCart());
        dispatch(setUser(userData));
        history.push("/");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      setCancelToken(null);
    }
  };

  useEffect(() => {
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Request canceled due to component unmount");
        setLoading(false);
      }
    };
  }, [cancelToken]);

  return (
    <section className="inner-breeder-area breeder-bg">
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <h2>Login</h2>
          {/* <Spin tip="Loading" size="small">
            <div className="content" />
          </Spin> */}
          <div className="col-12">
            <form
              onSubmit={handleSubmit}
              style={{ maxWidth: "500px", margin: "auto" }}
            >
              <div
                className={`form-group${
                  focusedInput === "email" ? " focused" : ""
                }`}
              >
                <input
                  type="email"
                  className="form-control custom-input"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onFocus={() => handleFocus("email")}
                  onBlur={handleBlur}
                  placeholder="Email"
                  required
                  style={{ height: "55px" }} // Adjust the height as needed
                />
              </div>
              <div
                className={`form-group${
                  focusedInput === "password" ? " focused" : ""
                }`}
              >
                <input
                  type="password"
                  className="form-control custom-input"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  onFocus={() => handleFocus("password")}
                  onBlur={handleBlur}
                  placeholder="Password"
                  required
                  style={{ height: "55px" }} // Adjust the height as needed
                />
              </div>
              <button type="submit" className="btn" style={{ height: "40px" }}>
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Logging in...
                  </>
                ) : (
                  "Login"
                )}
              </button>
            </form>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <p>
                Don't have a Account Yet?{" "}
                <Link style={{ color: "#008140" }} to="/register">
                  Register
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginSlider;
