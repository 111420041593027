import { useQuery } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchProductData } from "../utils/apis";

function Counter() {
  const {
    isPending: productIsPending,
    isError: productIsError,
    data: productData,
    error: productError,
  } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProductData,
  });

  const renderLoader = () => (
    <div className="text-center">
      <div className="spinner-border text-danger" role="status">
        <span className="visually-hidden"></span>
      </div>
    </div>
  );

  const renderError = (error) => <span>Error: {error?.message}</span>;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobile && (
        <section
          className="counter-area counter-bg"
          style={{
            backgroundImage: "url('img/bg/counter_bg.jpg')",
            marginTop: "100px",
          }}
        >
          <div className="col-lg-12">
            <div className="shop-wrap">
              <h4 className="title">Products</h4>
              {productIsPending && renderLoader()}
              {productError ? renderError() : null}
              {!productIsPending && !productError && productData && (
                <div className="row justify-content-center">
                  {productData.map((product) => (
                    <div
                      key={product.id}
                      className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6"
                    >
                      <div className="shop-item mb-55">
                        <div className="shop-thumb">
                          <Link to={`/shop-details/${product.id}`}>
                            <img src={product.image} alt="" />
                          </Link>
                        </div>
                        <div className="shop-content">
                          <span>{product.category}</span>
                          <h4 className="title">
                            <Link to={`/shop-details/${product.id}`}>
                              {product.title}
                            </Link>
                          </h4>
                          <div className="shop-content-bottom">
                            <span className="price">{product.price}</span>
                            <span className="add-cart">
                              <Link to={`/shop-details/${product.id}`}>
                                BUY NOW +
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Counter;
