import React from "react";
// import { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src="img/icon/arrow.png" alt="PREV" />
    </button>
  );
}
const dummySlides = [
  {
    id: 1,
    image: "img/top-wear/slide1.webp",
    title: "Kitty Wear",
    link: "/toylist",
  },
  {
    id: 2,
    image: "img/top-wear/slide2.webp",
    title: "Baby's Body Lotion",
    link: "/toylist",
  },
  {
    id: 3,
    image: "img/top-wear/slide3.webp",
    title: "Swing Chair",
    link: "/toylist",
  },
  {
    id: 4,
    image: "img/top-wear/slide4.webp",
    title: "Baby's Towel",
    link: "/toylist",
  },
];

function BreedServices() {
  const settings = {
    nav: true,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="adoption-shop-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="counter-title text-center mb-65">
              <h6 className="sub-title">Why Choose Us?</h6>
              <h2 className="title">Authentic Masalas for Flavorful Moments</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="counter-item">
              <h2 className="count">
                <span className="odometer">85</span>%
              </h2>
              <p>Customers Satisfied </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="counter-item">
              <h2 className="count">
                <span className="odometer">15</span>+
              </h2>
              <p>years of experience</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="counter-item">
              <h2 className="count">
                <span className="odometer">50</span>K
              </h2>
              <p>Varieties of Spices</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="counter-item">
              <h2 className="count">
                <span className="odometer">55</span>+
              </h2>
              <p>Spicy Deals</p>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              {/* <h2 className="display-4 mb-4">About Us</h2> */}
              <h3 className="mb-3">AKA Masala</h3>

              <p className="lead text-justify">
                Namaste! Welcome to AKA Masala, a brand that has blossomed from
                the heart of our home kitchen to become a prominent name in the
                world of spices. For over 15 years, our aromatic symphony of
                flavors has been enchanting kitchens across the nation.
              </p>
              <div className="divider bg-primary mx-auto mb-5"></div>
              <h3 className="mb-3">Our Homegrown Beginnings</h3>
              <p className="text-justify">
                Founded in 2009 as a humble home business, AKA Masala had its
                roots embedded in the rich tradition of Indian spice crafting.
                What started as a personal pursuit of passion and culinary
                excellence evolved into a brand that resonates with every Indian
                household.
              </p>
              <h3 className="mt-5 mb-3">The Birth of AKA Masala</h3>
              <p className="text-justify">
                In our pursuit of perfection, AKA Masala emerged as a brand
                committed to delivering quality, authenticity, and a burst of
                flavors to your kitchen. The transition from a home business to
                a brand symbolizes our dedication to sharing the essence of
                Indian spices with you.
              </p>
              <h3 className="mt-5 mb-3">
                Crafting Excellence Beyond Boundaries
              </h3>
              <p className="text-justify">
                At AKA Masala, we take pride in crafting masalas that tell the
                stories of generations past. Our blends are a harmonious blend
                of tradition and innovation, ensuring each packet carries the
                warmth of home-cooked meals.
              </p>
              <h3 className="mt-5 mb-3">15 Years of Flavorful Tradition</h3>
              <p className="text-justify">
                Celebrating 15 years, AKA Masala stands as a testament to our
                commitment to elevating India's culinary landscape. We've grown
                from a cherished secret in our own kitchen to becoming a trusted
                name in yours.
              </p>
              <h3 className="mt-5 mb-3">Why AKA Masala</h3>
              <ul className="list-unstyled">
                <li>
                  <strong>Pure Ingredients:</strong> AKA Masala sources the
                  finest spices from across India to ensure the purest flavors
                  in every blend.
                </li>
                <li>
                  <strong>Masterful Blending:</strong> Our skilled artisans at
                  AKA Masala meticulously combine spices to preserve the
                  authenticity of regional recipes.
                </li>
                <li>
                  <strong>Customer Delight:</strong> Your satisfaction is our
                  priority at AKA Masala. We strive to deliver products that
                  bring joy to your kitchen.
                </li>
              </ul>
              <p className="lead text-justify mt-5">
                As we continue our flavorful journey, AKA Masala remains
                dedicated to elevating the culinary experience of every Indian
                household. Join us in savoring the richness of India's spice
                heritage. Let AKA Masala be your trusted companion in the
                delightful world of masalas!
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default BreedServices;
